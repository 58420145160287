import React from "react";
import style from "./backButton.module.css";

const BackButton = ({display, onClick, customText}) => {
    return (
        <>
            {display && <button className={style.backButton} onClick={onClick}>{customText ? customText : "< Back"}</button>}
        </>
    )
}

export default BackButton;