import {
    ARPS_RETRIEVED,
    SET_BANNER,
} from './actionTypes';
import { userAuthHeaders } from './authorizationHeaders';
import axios from 'axios';
export const getAllArps = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/arp/all`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: ARPS_RETRIEVED,
            payload: res.data.arps
        });
        }).catch(err => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
}

export const createArp = (parameters) => async (dispatch, getState) => {
    await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/arp`, parameters, userAuthHeaders(getState)).then((res) => {
        const arps = [...getState().arps];
        arps.push(res.data.arp);
        dispatch({
            type: ARPS_RETRIEVED,
            payload: arps
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Created New ARP.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}

export const toggleSubmittedArp = (parameters) => async (dispatch, getState) => {
    axios.put(`${process.env.REACT_APP_SERVER_DOMAIN}/arp/student/submitted`, parameters, userAuthHeaders(getState)).then((res) => {
        // Put operations will not return any response, assuming we get a 200 we just update state with the parameters we provided
        // Shallow copy to rerender state
        const arps = [...getState().arps].filter(arp => arp.id !== res.data.arp.id);
        arps.push(res.data.arp);
        dispatch({
            type: ARPS_RETRIEVED,
            payload: arps
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Updated ARP.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
};

export const updateArp = (parameters) => async (dispatch, getState) => {
    axios.put(`${process.env.REACT_APP_SERVER_DOMAIN}/arp`, parameters, userAuthHeaders(getState)).then((res) => {
        // Put operations will not return any response, assuming we get a 200 we just update state with the parameters we provided
        // Shallow copy to rerender state
        const arps = [...getState().arps].filter(arp => arp.id !== res.data.arp.id);
        arps.push(res.data.arp);
        dispatch({
            type: ARPS_RETRIEVED,
            payload: arps
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Updated ARP.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}

export const deleteArp = (parameters) => async (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/arp/delete`, parameters, userAuthHeaders(getState)).then(() => {
        // Put operations will not return any response, assuming we get a 200 we just update state with the parameters we provided
        // Shallow copy to rerender state
        const arps = [...getState().arps].filter(arp => arp.id !== parameters.id);
        dispatch({
            type: ARPS_RETRIEVED,
            payload: arps
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Deleted ARP.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}