import React, { useEffect, useState } from "react";
import style from './scheduleModal.module.css';
import Select from "../../../select/select";
import Button from "../../../button/button";
import { useDispatch, useSelector } from "react-redux";
import { updateSchedule } from "../../../../actions/scheduleActions";

const getHours = () => {
    const hours = [];
    for(let i = 0; i <= 23; i++) {
        if (i < 10) {
            hours.push({ label: `0${i}`, value: `0${i}`});
        } else {
            hours.push({ label: `${i}`, value: `${i}`});
        };
    };
    return hours;
};

const getMinutes = () => {
    const minutes = [];

    for(let i = 0; i <= 59; i++) {
        if (i < 10) {
            minutes.push({ label: `0${i}`, value: `0${i}`});
        } else {
            minutes.push({ label: `${i}`, value: `${i}`});
        };
    };
    return minutes;
};

const ScheduleModal = ({ selectedDay, selectedSchedule, schedule }) => {
    const [startHour, setStartHour] = useState(`00`);
    const [startMinute, setStartMinute] = useState(`00`);
    const [endHour, setEndHour] = useState(`00`);
    const [endMinute, setEndMinute] = useState(`00`);
    const alterScheduleFailed = useSelector(state => state.alterScheduleFailed);
    const dispatch = useDispatch();

    const handleSave = () => {
        dispatch(updateSchedule({
            day_id: selectedDay,
            period: selectedSchedule,
            startTime: `${startHour}:${startMinute}:00`,
            endTime: `${endHour}:${endMinute}:00`
        }));
    }

    useEffect(() => {
        const currentSchedule = schedule.find(item => (item.day_id === selectedDay && item.period === selectedSchedule));
        if(currentSchedule) {
            const { startTime, endTime } = currentSchedule;
            const minutes = getMinutes();
            const hours = getHours();
            setStartHour(hours.find(item => item.value === startTime.split(':')[0]).value);
            setStartMinute(minutes.find(item => item.value === startTime.split(':')[1]).value);
            setEndHour(hours.find(item => item.value === endTime.split(':')[0]).value);
            setEndMinute(minutes.find(item => item.value === endTime.split(':')[1]).value);
        }
    }, []);

    return (
        <div>
            <div className={style.horizontal}>
                <Select header="Start Hour" value={startHour} options={getHours()} onChange={(e) => setStartHour(e.target.value)} />
                <Select header="Start Minute" value={startMinute} options={getMinutes()} onChange={e => setStartMinute(e.target.value)} />
            </div>
            <div className={style.horizontal}>
                <Select header="End Hour" value={endHour} options={getHours()} onChange={e => setEndHour(e.target.value)} />
                <Select header="End Minute" value={endMinute} options={getMinutes()} onChange={e => setEndMinute(e.target.value)} />
            </div>
            <div className={style.buttonContain}>
                {alterScheduleFailed && <p className={style.failureMessage}>Failed to update</p>}
                <Button value="Save" onClick={handleSave} />
            </div>
        </div>
    )
};

export default ScheduleModal;