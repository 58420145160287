import React from "react";
import styles from './button.module.css';

const Button = ({ onClick, className, value, style, disabled }) => {
    return (
        <button className={className ? className : styles.button} onClick={onClick} style={style} disabled={disabled}>
            {value}
        </button>
    )
}

export default Button;