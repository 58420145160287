import React, { useState } from "react";
import style from './listComponent.module.css';
import Input from "../input/input";

const ListComponent = ({ items, columns, selector, searchOptions, onClick, selectedItem, button }) => {
    const [value, setValue] = useState('');
    let filteredItems = items;
    if(searchOptions) {
        filteredItems = items.filter(item => searchOptions.filter(item).toLowerCase().includes(value.toLowerCase()))
    }
    return (
        <div className={style.container}>
            <div className={style.searchOptions}>
                {searchOptions && <Input value={value} onChange={e => setValue(e.target.value)} placeholder={searchOptions.placeholder} />}
                {button && button}
            </div>
            <div className={style.rowContain} style={{ height: searchOptions ? '94%' : '99%' }}>
                {filteredItems.map(item => (
                    <div key={filteredItems.indexOf(item)} className={style.row} style={{ backgroundColor: ((selectedItem || selectedItem === 0) && item[selector] === selectedItem) ? 'lightblue' : 'white'}} onClick={() => onClick && onClick(item[selector])}>
                        {columns.map(column => (
                             <div key={columns.indexOf(column)} className={style.cell}>{item[column]}</div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
};

export default ListComponent;