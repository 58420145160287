import axios from "axios";
import { SET_BANNER, STUDENTS_RETRIEVED } from "./actionTypes";
import { userAuthHeaders } from "./authorizationHeaders";

export const getAllStudents = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/students/all`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: STUDENTS_RETRIEVED,
            payload: res.data.students
        });
        }).catch((err) => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
};