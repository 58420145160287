import {
    SET_BANNER,
    USERS_RETRIEVED,
} from './actionTypes';
import { userAuthHeaders } from './authorizationHeaders';
import axios from 'axios';
export const getAllUsers = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/users/all`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: USERS_RETRIEVED,
            payload: res.data.users
        });
        }).catch((err) => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
}

export const updateUser = (parameters) => async (dispatch, getState) => {
    axios.put(`${process.env.REACT_APP_SERVER_DOMAIN}/users`, parameters, userAuthHeaders(getState)).then((res) => {
        const users = [...getState().users].filter(user => user.id !== res.data.user.id);
        users.push(res.data.user);
        dispatch({
            type: USERS_RETRIEVED,
            payload: users
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Updated Account.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}