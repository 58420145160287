import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./tabs.module.css";
import { getLocationVisits } from "../../../actions/visitActions";
import ListComponent from "../../../components/listComponent/listComponent";
import Modal from "../../../components/modal/modal";
import Button from '../../../components/button/button';
import LocationModal from "../../../components/modal/contents/locationModal/locationModal";
import { convertToCentral } from "../../../util";
import Table from "../../../components/table/table";

const LocationsTab = () => {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [showLocationModal, setShowLocationModal] = useState(false);

    const locations = useSelector(state => state.locations);

    useEffect(() => {
        setSelectedLocation(null);
        setShowLocationModal(false);
    }, [locations])

    const setLocation = (locationId) => {
        if(selectedLocation?.id === locationId) {
            setSelectedLocation(null);
            setShowLocationModal(false);
        } else {
            setSelectedLocation(locations.find(location => location.id === locationId));
            setShowLocationModal(true);
        }
    };


    const columns = [
        {
            title: 'Location Name',
            accessor: 'location_name',
            filter: true
        },
        {
            title: 'Location Password',
            accessor: 'location_password',
            filter: true
        },
        {
            title: '',
            accessor: 'id',
            element: (e) => <Button value="Update" onClick={() => setLocation(e)} style={{ fontSize: '10pt', height: '40px', padding: '5px 15px'}} />
        }
    ];

    const headerConfig =  {
        rightContent: <Button onClick={() => setShowLocationModal(true)} value='Create Location' style={{ marginRight: '10px'}} />,
        middleContent: <h2 className={style.header}>Locations</h2>
    };

    return (
        <>
            <Modal
                display={showLocationModal}
                dismiss={() => {
                    setSelectedLocation(null);
                    setShowLocationModal(false);
                }}
                header={selectedLocation ? "Update Location" : "Create Location"}
            >
                <LocationModal existingLocation={selectedLocation} />
            </Modal>
            <Table columns={columns} data={locations} headerConfig={headerConfig} />
        </>
    )
};

export default LocationsTab;