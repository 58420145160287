import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./tabs.module.css";
import { getLocationVisits } from "../../../actions/visitActions";
import ListComponent from "../../../components/listComponent/listComponent";
import Modal from "../../../components/modal/modal";
import Button from '../../../components/button/button';
import AccountModal from "../../../components/modal/contents/accountModal/accountModal";
import { convertToCentral } from "../../../util";
import Table from "../../../components/table/table";

const AccountTab = () => {
    const [selectedUser, setSelectedUser] = useState(null);

    const users = useSelector(state => state.users);

    useEffect(() => {
        setSelectedUser(null);
    }, [users])


    const columns = [
        {
            title: 'Username',
            accessor: 'username',
            filter: true
        },
        {
            title: 'Password',
            accessor: 'password',
            filter: true
        },
        {
            title: 'Teacher ID',
            accessor: 'teacher_id',
            filter: true
        },
        {
            title: 'Roles',
            accessor: 'roles',
            filter: true
        },
        {
            title: '',
            accessor: 'id',
            element: (e) => <Button value="Update" onClick={() => setSelectedUser(e)} style={{ fontSize: '10pt', height: '40px', padding: '5px 15px'}} />
        }
    ];

    const headerConfig =  {
        middleContent: <h2 className={style.header}>Account Information</h2>
    };

    return (
        <>
            <Modal
                display={selectedUser}
                dismiss={() => setSelectedUser(null)}
                header="Update Account"
            >
                <AccountModal selectedUser={users.find(user => user.id === selectedUser)} />
            </Modal>
            <Table columns={columns} data={users.map(user => ({ ...user, roles: user.roles.join(', ')}))} headerConfig={headerConfig} />
        </>
    )
};

export default AccountTab;