import React from "react";
import style from './input.module.css';

const Input = (props) => {
    return (
        <div className={style.container} style={props.containerStyle}>
            {props.header && <div className={style.header}>{props.header}</div>}
            <input type={props.type} style={props.style} className={style.input} value={props.value} onChange={props.onChange} placeholder={props.placeholder} />
        </div>
    )
}

export default Input;