import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import style from "./tabs.module.css";
import ListComponent from "../../../components/listComponent/listComponent";
import Modal from "../../../components/modal/modal";
import ScheduleModal from "../../../components/modal/contents/scheduleModal/scheduleModal";
import Button from "../../../components/button/button";

export const daysOfWeek = [
    {
        name: 'Sunday',
        id: 0
    },
    {
        name: 'Monday',
        id: 1
    },
    {
        name: 'Tuesday',
        id: 2
    },
    {
        name: 'Wednesday',
        id: 3
    },
    {
        name: 'Thursday',
        id: 4
    },
    {
        name: 'Friday',
        id: 5
    },
    {
        name: 'Saturday',
        id: 6
    }
];
const ScheduleTab = () => {
    const schedule = useSelector(state => state.schedule);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedSchedule, setSelectedSchedule] = useState(null);

    useEffect(() => {
        setSelectedSchedule(null);
    }, [schedule]);

    const setDay = (day) => {
        if(selectedDay === day) {
            setSelectedDay(null);
        } else {
            setSelectedDay(day);
        }
    }

    return (
        <div className={style.container}>
            <Modal
                display={selectedSchedule}
                dismiss={() => {
                    setSelectedSchedule(null);
                }}
                header="Update Schedule"
            >
                <ScheduleModal selectedDay={selectedDay} selectedSchedule={selectedSchedule} schedule={schedule} />
            </Modal>
            <ListComponent
                items={daysOfWeek}
                columns={['name']}
                selector='id'
                onClick={setDay}
                selectedItem={selectedDay}
            />
            {(selectedDay || selectedDay === 0) && (
                <ListComponent items={schedule.filter(sched => sched.day_id === selectedDay)} columns={['period', 'startTime', 'endTime']} selector="period" onClick={setSelectedSchedule} selectedItem={selectedSchedule}/>
            )}
        </div>
    );
};

export default ScheduleTab;