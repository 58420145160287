import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../input/input';
import Button from '../../../button/button';
import { updateUser } from '../../../../actions/usersActions';
import MultiSelect from '../../../select/multiSelect';

const AccountModal = ({ selectedUser }) => {
    const user = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [password, setPassword] = useState(selectedUser.password);
    const [selectedValues, setSelectedValues] = useState(selectedUser.roles);

    const submit = () => {
        dispatch(updateUser({
            id: selectedUser.id,
            password: password,
            roles: selectedValues
        }));
    }

    return (
        <div>
            <Input header="Password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            {user.roles.includes('ADMINISTRATOR') && (
                <MultiSelect
                    value={selectedValues}
                    options={['ADMINISTRATOR','DETENTION','ARP','VIEW_ALL']}
                    header="Role"
                    onChange={(e) => {
                        if(selectedValues.includes(e)) {
                            setSelectedValues(selectedValues.filter(value => value !== e));
                        } else {
                            setSelectedValues([...selectedValues, e]);
                        }
                    }}
                />
            )}
            <Button value="Save" onClick={submit} />
        </div>
    );
};

export default AccountModal;