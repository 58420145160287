import {
    LOCATION_LOGIN_SUCCESSFUL,
    USER_LOGIN_SUCCESSFUL,
    GET_USER_SUCCESSFUL,
    GET_LOCATION_SUCCESSFUL,
    SET_BANNER,
    LOGOUT
} from './actionTypes';
import { locationAuthHeaders, userAuthHeaders } from './authorizationHeaders';
import axios from 'axios';

// Login Location
export const locationLogin = (parameters) => dispatch => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/auth`, parameters).then((res) => {
        localStorage.setItem("locationAuthToken", res.data.locationAuthToken);
        localStorage.setItem("locationAuthExpiresBy", res.data.expiresBy);
        dispatch({
            type: LOCATION_LOGIN_SUCCESSFUL,
            payload: res.data
        });
    }).catch(err => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
};

// Login User
export const userLogin = (parameters) => dispatch => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/user`, parameters).then((res) => {
        localStorage.setItem("userAuthToken", res.data.userAuthToken);
        localStorage.setItem("userAuthExpiresBy", res.data.expiresBy);
        dispatch({
            type: USER_LOGIN_SUCCESSFUL,
            payload: res.data
        });
    }).catch(err => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
};

export const getUser = () => (dispatch, getState) => {
    axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/user`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: GET_USER_SUCCESSFUL,
            payload: res.data
        });
    }).catch(err => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
};

export const getLocation = () => (dispatch, getState) => {
    axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/location`, locationAuthHeaders(getState)).then((res) => {
        dispatch({
            type: GET_LOCATION_SUCCESSFUL,
            payload: res.data
        });
    }).catch(err => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
};

export const logout = () => dispatch => {
    localStorage.removeItem("userAuthToken");
    localStorage.removeItem("locationAuthToken");
    localStorage.removeItem("userAuthExpiresBy");
    localStorage.removeItem("locationAuthExpiresBy");
    dispatch({
        type: LOGOUT
    });
    dispatch({
        type: SET_BANNER,
        payload: { msg: 'Logged out.', color: 'green' }
    });
};