import React, { useState } from "react";
import style from './manualCheckin.module.css';
import Button from "../button/button";

const ManualCheckin = ({ callback }) => {
    const [studentId, setStudentId] = useState("");


    const handleInput = (character) => {
        if(character === "<") {
            setStudentId(studentId.slice(0, -1));
        } else if(character === "+") {
            callback(studentId);
            setStudentId("");
        } else if(studentId.length < 6) {
            setStudentId(studentId + character);
        }
    }

    return (
        <div className={style.container}>
            <h2 className={style.header}>Student Checkin:</h2>
            <div className={style.displayRow}>{Array.from(studentId).map(char => (<h2 key={Array.from(studentId).indexOf(char)}>{char}</h2>))}</div>
            <div className={style.row}>
                <Button value="1" className={style.button} onClick={() => handleInput( "1")}/>
                <Button value="2" className={style.button} onClick={() => handleInput( "2")}/>
                <Button value="3" className={style.button} onClick={() => handleInput("3")}/>
            </div>
            <div className={style.row}>
                <Button value="4" className={style.button} onClick={() => handleInput("4")}/>
                <Button value="5" className={style.button} onClick={() => handleInput("5")}/>
                <Button value="6" className={style.button} onClick={() => handleInput("6")}/>
            </div>
            <div className={style.row}>
                <Button value="7" className={style.button} onClick={() => handleInput("7")}/>
                <Button value="8" className={style.button} onClick={() => handleInput("8")}/>
                <Button value="9" className={style.button} onClick={() => handleInput("9")}/>
            </div>
            <div className={style.row}>
                <Button value="Delete" className={style.redButton} onClick={() => handleInput("<")}/>
                <Button value="0" className={style.button} onClick={() => handleInput("0")}/>
                <Button value="Submit" className={style.greenButton} onClick={() => handleInput("+")}/>
            </div>
        </div>
    )
};

export default ManualCheckin;