import React, { useEffect, useState } from 'react';
import style from './table.module.css';
import Input from '../input/input';

const Table = ({ columns, data, button, headerConfig, sortOverride }) => {
    const filters = columns.filter(column => column.filter).map(column => column.accessor);
    const [filter, setFilter] = useState("");
    const [sortOperator, setSortOperator] = useState(null);

    useEffect(() => {
        if(sortOverride) {
            setSortOperator({ value: sortOverride, direction: '⟱'});
        }
    }, [sortOverride])

    const toggleSorting = (column) => {
        let operator = column.value || column.accessor;
        if (sortOperator && sortOperator.value === operator) {
            if (sortOperator.direction === '⟰') {
                setSortOperator({ value: operator, direction: '⟱'});
            } else {
                setSortOperator({ value: operator, direction: '⟰' });
            }
        } else {
            setSortOperator({ value: operator, direction: '⟰' });
        }
    }

    let transformedData = data.filter(obj => obj);
    if(filters.length > 0) {
        transformedData = transformedData.filter(value => {
            let filterString = "";
            filters.forEach((value2) => {
                filterString += ` ${value[value2]}`;
            })
            return filterString.toLowerCase().includes(filter.toLowerCase());
        })
    }

    if (sortOperator !== null) {
        transformedData = transformedData.sort((a, b) => {
            const valueA = a[sortOperator.value];
            const valueB = b[sortOperator.value];
            
            if(!valueA) return sortOperator.direction === '⟰' ? -1 : 1;
            if(!valueB) return sortOperator.direction === '⟰' ? 1 : -1;

    
            // Check if the values are numbers
            const isNumberA = !isNaN(parseFloat(valueA)) && isFinite(valueA);
            const isNumberB = !isNaN(parseFloat(valueB)) && isFinite(valueB);
    
            if (isNumberA && isNumberB) {
                // Both values are numbers
                if (sortOperator.direction === '⟰') {
                    return parseFloat(valueA) - parseFloat(valueB);
                } else {
                    return parseFloat(valueB) - parseFloat(valueA);
                }
            } else {
                // Handle string comparisons
                if (sortOperator.direction === '⟰') {
                    return valueB.localeCompare(valueA);
                } else {
                    return valueA.localeCompare(valueB);
                }
            }
        });
    }

    const headerColumnStyle = {
        height: '38px',
        padding: '0',
        margin: '0',
    };

    const headerInputStyle = {
        borderRadius: '0',
        width: '350px'
    }

    const rowContainStyle = {
        height: `calc(100% - ${filters.length > 0 ? '76px' : '38px'})`
    };

    return (
        <>
            {headerConfig && (
                <div className={style.header}>
                    <div className={style.headerSection} style={{ justifyContent: 'flex-start'}}>{headerConfig.leftContent}</div>
                    <div className={style.headerSection} style={{ justifyContent: 'center'}}>{headerConfig.middleContent}</div>
                    <div className={style.headerSection} style={{ justifyContent: 'flex-end'}}>{headerConfig.rightContent}</div>
                </div>
            )}
            <div className={style.table} style={{ height: headerConfig ? 'calc(100% - 45px)' : '100%'}}>
                {(filters.length > 0 && data.length > 1) && (
                    <div className={style.filterContain}>
                        <Input value={filter} onChange={(e) => setFilter(e.target.value)} containerStyle={headerColumnStyle} style={headerInputStyle} placeholder="Filter..."/>
                        {button && button}
                    </div>
                    
                )}
                {
                    transformedData.length === 0 ? <h3 style={{ textAlign: 'center', color: 'darkslategray', margin: '0' }}>No Results.</h3> : (
                        <>
                            <div className={style.columnHeaderContain} style={headerColumnStyle}>
                                {columns.map(column => {
                                    let sortSymbol = "";
                                    if((column.accessor && column.accessor === sortOperator?.value) || (column.value && column.value === sortOperator?.value)) {
                                        sortSymbol = ` ${sortOperator?.direction}`;
                                    }
                                    return (
                                        <div style={column.style} className={style.columnHeaderEntry} onClick={() => !column.ignoreSort && toggleSorting(column)} key={columns.indexOf(column)}>
                                            {`${column.title}${sortSymbol}`}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={style.rows} style={rowContainStyle}>
                                {   
                                    transformedData.map(entry => (
                                        <div className={style.rowContain} key={transformedData.indexOf(entry)}>
                                            {columns.map(column => (
                                                <div style={column.style} className={style.rowEntry} key={columns.indexOf(column)}>{column.element ? column.element(column.accessor ? entry[column.accessor] : entry) : entry[column.accessor]}</div>
                                            ))}
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                }
                
            </div>
        </>
    )
}

export default Table;