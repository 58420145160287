import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationLogin, userLogin } from '../../actions/actions';
import style from './login.module.css';
import Input from '../../components/input/input';
import BackButton from "../../components/backButton/backButton";
import Button from "../../components/button/button";

const Login = () => {
    const dispatch = useDispatch();

    const [loginType, setLoginType] = useState(null);

    const [locationName, setLocationName] = useState("");
    const [locationPassword, setLocationPassword] = useState("");
    const [username, setUsername] = useState("");
    const [userPassword, setUserPassword] = useState("");

    const getContent = () => {
        if(loginType === "User") {
            return (
                <>
                    <h1 className={style.header}>User Login</h1>
                    <Input header="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <Input header="Password" value={userPassword} onChange={(e) => setUserPassword(e.target.value)} type="password" />
                    <button className={style.loginButton} onClick={() => dispatch(userLogin({ username, password: userPassword }))}>Login</button>
                </>
            );
        } else if (loginType === "Location") {
            return (
                <>
                    <h1 className={style.header}>Location Login</h1>
                    <Input header="Location Name" value={locationName} onChange={(e) => setLocationName(e.target.value)} />
                    <Input header="Password" value={locationPassword} onChange={(e) => setLocationPassword(e.target.value)} type="password" />
                    <Button className={style.loginButton} onClick={() => dispatch(locationLogin({ locationName, password: locationPassword }))} value="Login" />
                </>
            );
        } else {
            return (
                <>
                    <h1 className={style.header}>Select Login Type</h1>
                    <div className={style.buttonContainer}>
                        <button className={style.loginTypeButton} onClick={() => setLoginType("Location")}>Location</button>
                        <button className={style.loginTypeButton} onClick={() => setLoginType("User")}>User</button>
                    </div>
                </>
            );
        }
    }
    
    return (
        <div className={style.container}>
            <BackButton display={loginType !== null} onClick={() => setLoginType(null)} />
            <div className={style.blockContainer}>
                {getContent()}
            </div>
        </div>
    );
}

export default Login;
