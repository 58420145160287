import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createArp, deleteArp, getAllArps, toggleSubmittedArp, updateArp } from '../../../../actions/arpActions';
import { getCourseStudents } from '../../../../actions/courseActions';

import Button from '../../../../components/button/button';
import Table from '../../../../components/table/table';
import Input from '../../../../components/input/input';

import style from '../tabs.module.css';
import styles from './arp.module.css';

const ARPTab = ({ setInSession }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const courseStudents = useSelector(state => state.courseStudents);
    const courses = useSelector(state => state.courses);
    const students = useSelector(state => state.students);
    const staff = useSelector(state => state.staff);
    const arps = useSelector(state => state.arps);


    const [selectedArp, setSelectedArp] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [studentIds, setStudentIds] = useState([]);
    const [homeworkTitle, setHomeworkTitle] = useState('');
    const [pageState, setPageState] = useState('OPEN');
    const [overrideReload, setOverrideReload] = useState(false);

    useEffect(() => {
        if(!overrideReload) {
            setSelectedArp(null);
            setSelectedCourse(null);
            setStudentIds([]);
            setHomeworkTitle('');
            setPageState('OPEN');
        } else {
            setSelectedArp(arps.find(arp => arp?.id === selectedArp?.id));
            setOverrideReload(false);
        }
    }, [arps]);

    const setCourse = async (course_id) => {
        dispatch(getCourseStudents(course_id));
        setSelectedCourse(course_id);
        setPageState('STUDENT_SELECTION');
    };

    const updateStudentIds = (student_id) => {
        if(studentIds.includes(student_id)) {
            setStudentIds(studentIds.filter(student => student !== student_id));
        } else {
            setStudentIds([...studentIds, student_id]);
        }
    }

    const viewArp = (id) => {
        setSelectedArp(arps.find(arp => arp.id === id));
        setPageState('VIEW_ARP');
    }

    const setUpdateArp = () => {
        dispatch(getCourseStudents(selectedArp.course_id));
        setStudentIds(selectedArp.students.filter(stdArp => !stdArp.resolved).map(stdArp => stdArp.student_id));
        setSelectedCourse(selectedArp.course_id);
        setHomeworkTitle(selectedArp.homeworkTitle);
        setPageState('UPDATE_ARP');
    }

    const getColumns = () => {
        if(pageState === 'OPEN') {
            return [
                {
                    title: 'Teacher',
                    accessor: 'teacher_name'
                },
                {
                    title: 'Course',
                    accessor: 'course_name'
                },
                {
                    title: 'Assignment',
                    accessor: 'homeworkTitle'
                },
                {
                    title: 'Remaining',
                    accessor: 'remaining'
                },
                {
                    title: '',
                    accessor: 'id',
                    ignoreSort: true,
                    element: (e) => (
                        <>
                            <Button value='View' onClick={() => viewArp(e)} style={{ marginRight: '10px' }} />
                            <Button value='Delete' onClick={() => dispatch(deleteArp({ id: e }))} />
                        </>
                    )
                },
            ];
        }
        if (pageState === 'CREATE') {
            return [
                {
                    title: 'Course',
                    accessor: 'course_name',
                    filter: true
                },
                {
                    title: 'Teacher',
                    accessor: 'teacher_name',
                    filter: true
                },
                {
                    title: '', 
                    accessor: 'course_id',
                    ignoreSort: true,
                    element: (e) => <Button value='Select' onClick={() => setCourse(e)} />
                }
            ];
        }
        if(pageState === 'STUDENT_SELECTION' || pageState === 'UPDATE_ARP') {
            return [
                {
                    title: '',
                    accessor: 'student_id',
                    style: { justifyContent: 'flex-end' },
                    element: (e) => (
                        <input
                            type='checkbox'
                            checked={studentIds.includes(e)}
                            onChange={() => updateStudentIds(e)}
                            style={{ height: '15px', width: '15px'}}
                        />
                    )
                },
                {
                    title: 'Student',
                    accessor: 'studentName',
                    style: { justifyContent: 'flex-start' },
                    filter: true
                }
            ];
        }
        if(pageState === 'VIEW_ARP') {
            return [
                {
                    title: 'Students',
                    accessor: 'studentName',
                },
                {
                    title: 'Status',
                    accessor: 'status'
                },
                {
                    title: '',
                    accessor: 'student_id',
                    ignoreSort: true,
                    element: (e) => (
                        <Button
                            value="Update Status"
                            onClick={() => {
                                setOverrideReload(true);
                                dispatch(toggleSubmittedArp({ id: selectedArp.id, student_id: e}))
                            }}
                        />
                    )
                }
            ];
        }
        
        return [];
    }

    const getData = () => {
        if(pageState === 'OPEN') {
            return arps.filter(arp => !arp.resolved).map(arp => {
                const course = courses.find(course => course.course_id === arp.course_id);
                const teacher = staff.find(teacher => teacher.teacher_id === course?.teacher_id);
                return {
                    teacher_name: `${teacher.last_name}, ${teacher.first_name}`,
                    course_name: `${course.course_name} ${course.expression}`,
                    homeworkTitle: arp.homeworkTitle,
                    remaining: arp.students.filter(stdArp => !stdArp.submitted).length,
                    id: arp.id
                }
            });
        }
        if (pageState === 'CREATE') {
            return courses.map(course => {
                const teacher = staff.find(teacher => teacher.teacher_id === course.teacher_id);
                return {
                    ...course,
                    course_name: `${course.course_name} ${course.expression}`,
                    teacher_name: `${teacher.last_name}, ${teacher.first_name}`
                };
            });
        }
        if (pageState === 'STUDENT_SELECTION' || pageState === 'UPDATE_ARP') {
            return courseStudents.map(e => {
                const student = students.find(stu => stu.student_id === e.student_id)
                return { ...student, studentName: `${student.last_name}, ${student.first_name}`}
            })
        }
        if (pageState === 'VIEW_ARP') {
            return selectedArp.students.map(stdArp => {
                const student = students.find(student => student.student_id === stdArp.student_id);
                return { studentName: `${student.last_name}, ${student.first_name}`, status: stdArp.submitted ? 'Received' : 'Missing', student_id: student.student_id }
            })
        }
        return [];
    }

    const getHeaderConfig = () => {
        let header;
        let rightContent;
        let leftContent;

        if(pageState === 'OPEN') {
            header = 'Open ARPs';
            rightContent = (
                <>
                    {(user.roles.includes('ADMINISTRATOR') || user.roles.includes('ARP')) && <Button onClick={() => setInSession(true)} value='Session' style={{ marginRight: '10px'}} />}
                    <Button onClick={() => setPageState('CREATE')} value='Create ARP' style={{ marginRight: '10px'}} />
                </>
            )
        }

        if(pageState === 'CREATE') {
            header = 'Create New ARP';
            leftContent = (
                <Button
                    className={styles.backButton}
                    onClick={() => {
                        setSelectedCourse(null);
                        setPageState('OPEN');
                    }}
                    value={'< Back'}
                />
            );
        }

        if(pageState === 'STUDENT_SELECTION') {
            header = courses.find(course => course.course_id === selectedCourse)?.course_name;
            leftContent = (
                <Button
                    className={styles.backButton}
                    value={'< Back'}
                    onClick={() => {
                        setStudentIds([]);
                        setPageState('CREATE');
                    }}  
                />
            );
        }

        if(pageState === 'VIEW_ARP') {
            const course =courses.find(course => course.course_id === selectedArp.course_id)
            header = `${course.course_name} - ${course.expression} - ${selectedArp.homeworkTitle}`;
            rightContent = <Button onClick={() => setUpdateArp()} value='Edit ARP/Add ARP' style={{ marginRight: '10px'}} />
            leftContent = (
                <Button
                    className={styles.backButton}
                    onClick={() => {
                        setSelectedArp(null);
                        setStudentIds([]);
                        setHomeworkTitle('');
                        setPageState('OPEN')
                    }}
                    value={'< Back'}
                />
            );
        }

        if(pageState === 'UPDATE_ARP') {
            header = `${courses.find(course => course.course_id === selectedArp.course_id).course_name} - ${selectedArp.homeworkTitle}`;
            leftContent = <Button className={styles.backButton} onClick={() => setPageState('VIEW_ARP')} value={'< Back'} />;
        }

        if((pageState === 'STUDENT_SELECTION' || pageState === 'UPDATE_ARP') && studentIds.length > 0) {
            rightContent = (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Input placeholder="Homework Title" value={homeworkTitle} onChange={(e) => setHomeworkTitle(e.target.value)} style={{ borderRadius: '0', marginRight: '10px'}} />
                    <Button value="Submit" disabled={!selectedCourse || homeworkTitle === ''} onClick={submit} style={{ marginRight: '10px'}} />
                </div>
            );
        }

        return {
            leftContent,
            rightContent,
            middleContent: <h2 className={style.header}>{header}</h2>
        };
    }
    
    const getSortOverride = () => {
        let sortOverride = 'studentName';

        if(pageState === 'OPEN') {
            sortOverride = 'homeworkTitle';
        }

        return sortOverride;
    }

    const submit = () => {
        const params = {
            homeworkTitle,
            studentIds,
            course_id: selectedCourse,
            id: selectedArp?.id
        }
        if(pageState === 'UPDATE_ARP') {
            dispatch(updateArp(params));
        } else {
            dispatch(createArp(params));
        }
    }


    

    const getContent = () => {
        return <Table columns={getColumns()} data={getData()} headerConfig={getHeaderConfig()} sortOverride={getSortOverride()} />
    }

    return (
        <div className={styles.container}>
            {getContent()}
        </div>
    )
};

export default ARPTab;