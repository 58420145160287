import {
    SET_BANNER,
    STAFF_RETRIEVED,
} from './actionTypes';
import { userAuthHeaders } from './authorizationHeaders';
import axios from 'axios';

export const getAllStaff = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/staff/all`, userAuthHeaders(getState)).then((res) => {
            dispatch({
                type: STAFF_RETRIEVED,
                payload: res.data.staff
            });
        }).catch(err => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
};