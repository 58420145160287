import axios from "axios";
import { SCHEDULE_RETRIEVED, SET_BANNER } from "./actionTypes";
import { userAuthHeaders } from "./authorizationHeaders";

export const getSchedule = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/schedule/all`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: SCHEDULE_RETRIEVED,
            payload: res.data.schedule
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    })
};


export const updateSchedule = (parameters) => async (dispatch, getState) => {
    axios.put(`${process.env.REACT_APP_SERVER_DOMAIN}/schedule`, parameters, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: SCHEDULE_RETRIEVED,
            payload: res.data.schedule
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Updated schedule.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}