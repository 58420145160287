const LOCATION_LOGIN_SUCCESSFUL = "LOCATION_LOGIN_SUCCESSFUL";
const USER_LOGIN_SUCCESSFUL = "USER_LOGIN_SUCCESSFUL";
const POST_VISIT_FAILURE = "POST_VISIT_FAILURE";
const POST_VISIT_ATTEMPTING = "POST_VISIT_ATTEMPTING";
const STUDENTS_RETRIEVED = "STUDENTS_RETRIEVED";
const LOCATIONS_RETRIEVED = "LOCATIONS_RETRIEVED";
const COURSES_RETRIEVED = "COURSES_RETRIEVED";
const COURSE_STUDENTS_RETRIEVED = "COURSE_STUDENTS_RETRIEVED";
const STAFF_RETRIEVED = "STAFF_RETRIEVED";
const VISITS_RETRIEVED = "VISITS_RETRIEVED";
const SCHEDULE_RETRIEVED = "SCHEDULE_RETRIEVED";
const GET_USER_SUCCESSFUL = "GET_USER_SUCCESSFUL";
const LOGOUT = "LOGOUT";
const SET_SOCKET_VISITS = "SET_SOCKET_VISITS";
const GET_LOCATION_SUCCESSFUL = "GET_LOCATION_SUCCESSFUL";
const ARPS_RETRIEVED = "ARPS_RETRIEVED";
const ARP_SESSION_RETRIEVED = "ARP_SESSION_RETRIEVED";
const ARP_PAST_SESSIONS_RETRIEVED = "ARP_PAST_SESSIONS_RETRIEVED";
const ARP_PAST_SESSION_RETRIEVED = "ARP_PAST_SESSION_RETRIEVED";
const DETENTIONS_RETRIEVED = "DETENTIONS_RETRIEVED";
const DETENTIONS_ENDED = "DETENTIONS_ENDED";
const ARP_SESSION_SUBMIT = "ARP_SESSION_SUBMIT";
const USERS_RETRIEVED = "USERS_RETRIEVED";
const ABSENCE_RETRIEVED = "ABSENCE_RETRIEVED";
const DETENTION_SESSION_RETRIEVED = "DETENTION_SESSION_RETRIEVED";
const SET_BANNER = "SET_BANNER";

export {
    SET_SOCKET_VISITS,
    LOCATION_LOGIN_SUCCESSFUL,
    USER_LOGIN_SUCCESSFUL,
    GET_USER_SUCCESSFUL,
    GET_LOCATION_SUCCESSFUL,
    POST_VISIT_FAILURE,
    POST_VISIT_ATTEMPTING,
    STUDENTS_RETRIEVED,
    LOCATIONS_RETRIEVED,
    COURSES_RETRIEVED,
    STAFF_RETRIEVED,
    VISITS_RETRIEVED,
    SCHEDULE_RETRIEVED,
    LOGOUT,
    COURSE_STUDENTS_RETRIEVED,
    ARPS_RETRIEVED,
    ARP_PAST_SESSIONS_RETRIEVED,
    ARP_PAST_SESSION_RETRIEVED,
    ARP_SESSION_RETRIEVED,
    ARP_SESSION_SUBMIT,
    DETENTIONS_RETRIEVED,
    DETENTIONS_ENDED,
    USERS_RETRIEVED,
    ABSENCE_RETRIEVED,
    DETENTION_SESSION_RETRIEVED,
    SET_BANNER,
};