import React from "react";
import style from './select.module.css';

const MultiSelect = (props) => {
    return (
        <div className={style.container} style={props.containerStyle}>
            {props.header && <div className={style.header}>{props.header}</div>}
            <ul className={style.input} value={props.value} onChange={props.onChange} style={{ height: '200px' }}>
                {props.options.map(option => (
                    <li
                        className={style.listItem}
                        key={props.options.indexOf(option)}
                        onClick={() => props.onChange(option)}
                        style={{ backgroundColor: props.value.includes(option) ? 'lightgray' : 'inherit'}}
                    >
                        {option}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default MultiSelect;