import React from "react";
import style from './modal.module.css';

const Modal = ({children, display, dismiss, header}) => {
    if(!display) return <></>;
    return (
        <div className={style.modalContain} onClick={dismiss}>
            <div className={style.modalContents} onClick={e => e.stopPropagation()}>
                <div className={style.container}>
                    {header && <h2 className={style.header}>{header}</h2>}
                    <div className={style.centerContain}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;