import {
    COURSES_RETRIEVED,
    COURSE_STUDENTS_RETRIEVED,
    SET_BANNER,
} from './actionTypes';
import { userAuthHeaders } from './authorizationHeaders';
import axios from 'axios';
export const getAllCourses = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/courses/all`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: COURSES_RETRIEVED,
            payload: res.data.courses
        });
        }).catch(err => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
}

export const getCourseStudents = (course_id) => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/courses/students`, {params: {course_id}, ...userAuthHeaders(getState)}).then((res) => {
        dispatch({
            type: COURSE_STUDENTS_RETRIEVED,
            payload: res.data.courseStudents
        });
        }).catch(err => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
}