import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import styles from './listenButton.module.css'

const ListenButton = ({ socket }) => {
    const [barcode, setBarcode] = useState("");
    const [barcodeEntered, setBarcodeEntered] = useState(false);
    const dispatch = useDispatch();
    

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeydown);
        }
    }, []);

    useEffect(() => {
        if(barcodeEntered) {
            handleScanComplete(barcode);
            setBarcodeEntered(false);
        }
    }, [barcodeEntered]);

    const handleKeydown = useCallback((input) => {
        if(input.keyCode === 13) {
            setBarcodeEntered(true);
            return;
        }

        if (input.keyCode === 16) {
            return;
        }
        setBarcode(prevState => prevState + input.key);
 
        setTimeout(() => {
            setBarcode(() => "");
        }, 1000);
    }, []);

    const handleScanComplete = (student_id) => {
        socket.emit('visit:post', student_id);
    }

    return <></>;
}

export default ListenButton;