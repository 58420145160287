import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./userLanding.module.css";
import { logout } from "../../actions/actions";
import LocationsTab from "./tabs/locationsTab";
import ARPTab from "./tabs/arp/arpTab";
import ScheduleTab from "./tabs/scheduleTab";
import { getAllLocations } from "../../actions/locationActions";
import { getAllCourses } from "../../actions/courseActions";
import { getAllStaff } from "../../actions/staffActions";
import { getAllStudents } from "../../actions/studentActions";
import { getSchedule } from "../../actions/scheduleActions";
import Button from "../../components/button/button";
import StudentVisitsTab from "./tabs/studentVisitsTab";
import { getAllArps } from "../../actions/arpActions";
import { getAllUsers } from "../../actions/usersActions";
import AccountTab from "./tabs/accountTab";
import ARPSessionTab from "./tabs/arp/arpSessionTab";
import { getDetentions } from "../../actions/detentionActions";
import DetentionsTab from "./tabs/detentionsTab";
import ARP from "./tabs/arp/arp";
import AbsenceTab from "./tabs/absenceTab";
import { getAbsence } from "../../actions/absenceActions";

const UserLanding = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const studentsLoaded = useSelector(state => state.studentsLoaded);
    const staffLoaded = useSelector(state => state.staffLoaded);
    const locationsLoaded = useSelector(state => state.locationsLoaded);
    const coursesLoaded = useSelector(state => state.coursesLoaded);
    const scheduleLoaded = useSelector(state => state.scheduleLoaded);
    const arpsLoaded = useSelector(state => state.arpsLoaded);
    const usersLoaded = useSelector(state => state.usersLoaded);
    const absenceLoaded = useSelector(state => state.absenceLoaded);
    
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        if(!staffLoaded) {
            dispatch(getAllStaff());
        }
        if(!studentsLoaded) {
            dispatch(getAllStudents());
        }
        if(!locationsLoaded) {
            dispatch(getAllLocations());
        }
        if(!coursesLoaded) {
            dispatch(getAllCourses());
        }
        if(!scheduleLoaded) {
            dispatch(getSchedule());
        }
        if(!arpsLoaded) {
            dispatch(getAllArps());
        }
        if(!usersLoaded) {
            dispatch(getAllUsers());
        }
        if(!absenceLoaded) {
            dispatch(getAbsence());
        }
    }, [user]);

    const logoutContent = () => {
        return (
            <div className={style.logoutContain}>
                <h3 className={style.header}>Logout?</h3>
                <Button value="No" onClick={() => setSelectedTab(0)} style={{ marginRight: '10px'}}/>
                <Button value="Yes" onClick={() => dispatch(logout())} />
            </div>
        );
    }

    

    const tabLayouts = [
        {
            name: "Student Visits", 
            contents: <StudentVisitsTab />
        },
        {
            name: "Schedules", 
            contents: <ScheduleTab />,
            roles: ['ADMINISTRATOR']
        },
        {
            name: "Locations", 
            contents: <LocationsTab />,
            roles: ['ADMINISTRATOR']
        },
        {
            name: "ARP", 
            contents: <ARP />
        },
        {
            name: "Detentions", 
            contents: <DetentionsTab />
        },
        {
            name: "Account",
            contents: <AccountTab />
        },
        {
            name: "Absence",
            contents: <AbsenceTab />
        },
        {
            name: "Logout",
            contents: logoutContent(),
        }
    ];
        
    return (
        <div className={style.container}>
            <div className={style.pageBody}>
                <div className={style.pageTabs}>
                    {
                        user && tabLayouts.filter(tab => tab.roles ? tab.roles.find(role => user.roles.includes(role)) : true).map(tab => (
                            <div
                                key={tabLayouts.indexOf(tab)}
                                className={style.tab}
                                style={{
                                    backgroundColor: selectedTab === tabLayouts.indexOf(tab) ? 'lightgray': 'darkgray'
                                }}
                                onClick={() => setSelectedTab(tabLayouts.indexOf(tab))}
                            >
                                {tab.name}
                            </div>
                        ))
                    }
                </div>
                <div className={style.pageTabBody}>
                    {tabLayouts[selectedTab].contents}
                </div>
            </div>
        </div>
    );
};

export default UserLanding;