import {
    LOCATIONS_RETRIEVED,
    SET_BANNER,
} from './actionTypes';
import { userAuthHeaders } from './authorizationHeaders';
import axios from 'axios';
export const getAllLocations = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/locations/all`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: LOCATIONS_RETRIEVED,
            payload: res.data.locations
        });
        }).catch((err) => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
}

export const createLocation = (parameters) => async (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/locations`, parameters, userAuthHeaders(getState)).then((res) => {
        const locations = [...getState().locations];
        locations.push(res.data.location);
        dispatch({
            type: LOCATIONS_RETRIEVED,
            payload: locations
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Created New Location.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}

export const updateLocation = (parameters) => async (dispatch, getState) => {
    axios.put(`${process.env.REACT_APP_SERVER_DOMAIN}/locations`, parameters, userAuthHeaders(getState)).then((res) => {
        const locations = [...getState().locations].filter(location => location.id !== res.data.location.id);
        locations.push(res.data.location);
        dispatch({
            type: LOCATIONS_RETRIEVED,
            payload: locations
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Updated Existing Location.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}