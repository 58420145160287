import React, { useEffect, useState } from "react";
import style from './locationModal.module.css';
import Input from "../../../input/input";
import Button from "../../../button/button";
import { useDispatch, useSelector } from "react-redux";
import { createLocation, updateLocation } from "../../../../actions/locationActions";

const LocationModal = ({ existingLocation }) => {
    const dispatch = useDispatch();
    const alterLocationFailed = useSelector(state => state.alterLocationFailed);
    const [locationName, setLocationName] = useState('');
    const [locationPassword, setLocationPassword] = useState('');

    useEffect(() => {
        if(existingLocation) {
            setLocationName(existingLocation.location_name);
            setLocationPassword(existingLocation.location_password);
        }
    }, [existingLocation]);

    const handleSave = () => {
        if(existingLocation) {
            dispatch(updateLocation({location_name: locationName, location_password: locationPassword, id: existingLocation.id }))
        } else {
            dispatch(createLocation({location_name: locationName, location_password: locationPassword }));
        }
    }

    return (
        <div>
            <Input header="Location Name" value={locationName} onChange={e => setLocationName(e.target.value)} />
            <Input header="Location Password" value={locationPassword} onChange={e => setLocationPassword(e.target.value)} />
            <div className={style.buttonContain}>
                {alterLocationFailed && <p className={style.failureMessage}>Failed to update</p>}
                <Button value="Save" onClick={handleSave} />
            </div>
        </div>
    );
}

export default LocationModal;