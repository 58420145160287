import {
    DETENTIONS_RETRIEVED,
    DETENTION_SESSION_RETRIEVED,
    DETENTIONS_ENDED,
    SET_BANNER,
} from './actionTypes';
import { userAuthHeaders } from './authorizationHeaders';
import axios from 'axios';
export const getDetentions = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/detentions/`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: DETENTIONS_RETRIEVED,
            payload: res.data.detentions
        });
        }).catch((err) => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
}

export const createDetention = (parameters) => async (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/detentions/`, parameters, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: DETENTIONS_RETRIEVED,
            payload: res.data.detentions
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Created New Detention.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}

export const deleteDetention = (parameters) => async (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/detentions/delete`, parameters, userAuthHeaders(getState)).then(() => {
        let detentions = [...getState().detentions];
        detentions = detentions.filter(detention => detention.id !== parameters.id)

        dispatch({
            type: DETENTIONS_RETRIEVED,
            payload: detentions
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Deleted Student From Detention.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}

export const getDetentionSession = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/detentions/session`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: DETENTION_SESSION_RETRIEVED,
            payload: res.data.sessionDetentions
        });
        }).catch((err) => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
}

export const updateDetention = (parameters) => async (dispatch, getState) => {
    axios.put(`${process.env.REACT_APP_SERVER_DOMAIN}/detentions/session`, parameters, userAuthHeaders(getState)).then((res) => {
        const sessionDetentions = [...getState().sessionDetentions].filter(detention => detention.id !== res.data.detention.id);
        sessionDetentions.push(res.data.detention);

        dispatch({
            type: DETENTION_SESSION_RETRIEVED,
            payload: sessionDetentions
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Updated Detention.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}

export const endDetention = () => async (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/detentions/end`, {}, userAuthHeaders(getState)).then(() => {
        dispatch({
            type: DETENTIONS_ENDED
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Ended Detention.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}