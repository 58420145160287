import {
    LOCATION_LOGIN_SUCCESSFUL,
    USER_LOGIN_SUCCESSFUL,
    POST_VISIT_FAILURE,
    POST_VISIT_ATTEMPTING,
    STUDENTS_RETRIEVED,
    STAFF_RETRIEVED,
    LOCATIONS_RETRIEVED,
    COURSES_RETRIEVED,
    VISITS_RETRIEVED,
    SCHEDULE_RETRIEVED,
    GET_USER_SUCCESSFUL,
    LOGOUT,
    SET_SOCKET_VISITS,
    GET_LOCATION_SUCCESSFUL,
    COURSE_STUDENTS_RETRIEVED,
    ARPS_RETRIEVED,
    ARP_SESSION_RETRIEVED,
    ARP_SESSION_SUBMIT,
    USERS_RETRIEVED,
    SET_BANNER,
    ARP_PAST_SESSIONS_RETRIEVED,
    ARP_PAST_SESSION_RETRIEVED,
    DETENTIONS_RETRIEVED,
    DETENTIONS_ENDED,
    DETENTION_SESSION_RETRIEVED,
    ABSENCE_RETRIEVED,
} from "./actions/actionTypes";

export const initialState = () => ({
// AUTH
    location: null,
    user: null,
    locationAuthToken: localStorage.getItem("locationAuthToken"),
    locationAuthExpiresBy: localStorage.getItem("locationAuthExpiresBy"),
    userAuthToken: localStorage.getItem("userAuthToken"),
    userAuthExpiresBy: localStorage.getItem("userAuthExpiresBy"),
// Location Screen
    visitList: [],
    visitFailure: null,
    visitAttempting: false,
// USER screen
    // ARP
    arps: [],
    arpsLoaded: false,
    arpSession: {},
    arpSessionLoaded: false,
    arpPastSessions: [],
    arpPastSessionsLoaded: false,
    arpPastSession: {},
    arpPastSessionLoaded: false,
    // Students
    students: [],
    studentsLoaded: false,
    // Locations
    locations: [],
    locationsLoaded: false,
    // Courses
    courses: [],
    coursesLoaded: false,
    // Course Students
    courseStudents: [],
    courseStudentsLoaded: false,
    // Staff
    staff: [],
    staffLoaded: false,
    // Visits
    visits: [],
    visitsLoaded: false,
    // Schedule
    schedule: [],
    scheduleLoaded: false,
    // Users
    users: [],
    usersLoaded: false,
    // Detentions
    detentions: [],
    detentionsLoaded: false,
    sessionDetentions: [],
    sessionDetentionsLoaded: false,
    // Absence
    absenceLoaded: false,
    absences: [],
// Info Banner
    banner: null
});


const reducer = (state = initialState(), action) => {
    switch(action.type) {
        case SET_BANNER:
            return {
                ...state,
                banner: action.payload
            }
        case LOCATION_LOGIN_SUCCESSFUL:
            return {
                ...state,
                location: action.payload.location,
                locationAuthToken: action.payload.locationAuthToken,
                locationAuthExpiresBy: action.payload.expiresBy
            };
        case USER_LOGIN_SUCCESSFUL:
            return {
                ...state,
                user: action.payload.user,
                userAuthToken: action.payload.userAuthToken,
                userAuthExpiresBy: action.payload.expiresBy
            };
        case GET_USER_SUCCESSFUL:
            return {
                ...state,
                user: action.payload
            };
        case GET_LOCATION_SUCCESSFUL:
            return {
                ...state,
                location: action.payload
            };
        case POST_VISIT_FAILURE:
            return {
                ...state,
                visitFailure: action.payload,
                visitAttempting: false
            };
        case POST_VISIT_ATTEMPTING:
            return {
                ...state,
                visitAttempting: true
            };
        case SET_SOCKET_VISITS:
            return {
                ...state,
                visitFailure: null,
                visitList: action.payload
            };
        case STUDENTS_RETRIEVED:
            return {
                ...state,
                studentsLoaded: true,
                students: action.payload
            };
        case LOCATIONS_RETRIEVED:
            return {
                ...state,
                locationsLoaded: true,
                locations: action.payload
            };
        case ARPS_RETRIEVED:
            return {
                ...state,
                arpsLoaded: true,
                arps: action.payload
            };
        case ARP_SESSION_RETRIEVED:
            return {
                ...state,
                arpSessionLoaded: true,
                arpSession: action.payload
            };
        case ARP_PAST_SESSIONS_RETRIEVED:
            return {
                ...state,
                arpPastSessionsLoaded: true,
                arpPastSessions: action.payload
            };
        case ARP_PAST_SESSION_RETRIEVED:
            return {
                ...state,
                arpPastSessionLoaded: true,
                arpPastSession: action.payload
            };
        case ARP_SESSION_SUBMIT:
            return {
                ...state,
                arpSessionLoaded: false,
                arpSession: {},
                arpsLoaded: false,
                arps: [],
            };
        case DETENTIONS_RETRIEVED:
            return {
                ...state,
                detentionsLoaded: true,
                detentions: action.payload
            };
        case DETENTION_SESSION_RETRIEVED:
            return {
                ...state,
                sessionDetentionsLoaded: true,
                sessionDetentions: action.payload
            };
        case DETENTIONS_ENDED:
            return {
                ...state,
                detentionsLoaded: false,
                detentions: [],
                sessionDetentionsLoaded: false,
                sessionDetentions: []
            }
        case USERS_RETRIEVED:
            return {
                ...state,
                usersLoaded: true,
                users: action.payload
            };
        case ABSENCE_RETRIEVED:
            return {
                ...state,
                absenceLoaded: true,
                absences: action.payload
            };
        case COURSES_RETRIEVED:
            return {
                ...state,
                coursesLoaded: true,
                courses: action.payload
            };
        case COURSE_STUDENTS_RETRIEVED:
            return {
                ...state,
                courseStudentsLoaded: true,
                courseStudents: action.payload
            };
        case STAFF_RETRIEVED:
            return {
                ...state,
                staffLoaded: true,
                staff: action.payload
            };
        case VISITS_RETRIEVED:
            return {
                ...state,
                visitsLoaded: true,
                visits: action.payload
            };
        case SCHEDULE_RETRIEVED:
            return {
                ...state,
                scheduleLoaded: true,
                schedule: action.payload
            };
        case LOGOUT:
            return initialState();
        default:
            return state;
    }
}

export default reducer;