export const convertToCentral = (dateTime) => {
    if(dateTime === null) {
        return "N/A";
    }
    const date = new Date(dateTime);
    const options = {
        year: 'numeric', 
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        hour12: true,
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'America/Chicago'
    }
    return date.toLocaleString('en-US', options);
}

export const convertToDateCentral = (dateTime) => {
    if(dateTime === null) {
        return "N/A";
    }
    const date = new Date(dateTime);
    const options = {
        month: 'long', // numeric month (no leading zero)
        day: '2-digit',
        timeZone: 'America/Chicago'
    }
    return date.toLocaleString('en-US', options);
}

export const convertVisitData = (visits, students, locations, staff, courses) => {
    return visits.map(visit => {
        const student = students.find(student => student.student_id === visit.student_id);
        const location = locations.find(location => location.id === visit.location_id);
        const teacher = staff.find(teacher => teacher.teacher_id === visit.teacher_id);
        const course = courses.find(course => course.course_id === visit.course_id);

        return {
            student: student ? `${student.first_name} ${student.last_name}` : "",
            location: location ? location.location_name : "",
            teacher: teacher ? `${teacher?.first_name} ${teacher?.last_name}` : "",
            course: course ? `${course.course_name} ${course.expression}` : "",
            timeIn: visit.timeIn,
            timeOut: visit.timeOut
        }
    });
}