import React from 'react';
import { useSelector } from 'react-redux';
import styles from './visitList.module.css';
import done from '../../assets/done.png';
import error from '../../assets/error.png';
import progress from '../../assets/progress.png';
import { convertToCentral } from '../../util';
import Button from '../button/button';

const VisitList = ({ setUserCheckout, userCheckout, handleManualSubmit }) => {
    const visitList = useSelector(state => state.visitList);
    const visitAttempting = useSelector(state => state.visitAttempting);
    const visitFailure = useSelector(state => state.visitFailure);
    const AttemptingVisit = (
        <div className={styles.row}><img src={progress} />Requesting...</div>
    );

    const VisitFailure = (
        <div className={styles.row}><img src={error} />Failed: {visitFailure}</div>
    );

    const callSetUserCheckout = (e, data) => {
        setUserCheckout(data === userCheckout ? "" : data);
        e.stopPropagation();
    }

    const callHandleManualSubmit = (e, data) => {
        setUserCheckout("");
        handleManualSubmit(data);
        e.stopPropagation();
    }

    return (
        <div className={styles.container}>
            {visitAttempting ? AttemptingVisit : <></>}
            {visitFailure ? VisitFailure : <></>}
            {visitList
                // Will filter out any check ins older than an hour, there will be a new checkin created in the backend at this point
                .filter(visit => new Date(visit.timeIn) > new Date(new Date().getTime() - (60 * 60 * 1000)))
                .sort((a, b) => (new Date(a.timeIn) > new Date(b.timeIn)) ? -1 : 1)
                .map(visit => {
                    return (
                        <div key={visitList.indexOf(visit)} className={styles.row} onClick={(e) => callSetUserCheckout(e, visit.student_id)}>
                            <img src={done} />
                            <div className={styles.cell}>{visit?.first_name}</div>
                            {visit?.middle_name && <div className={styles.cell}>{visit?.middle_name}</div>}
                            <div className={styles.cell}>{visit?.last_name}</div>
                            <div className={styles.cell}>{visit && convertToCentral(visit.timeIn)}</div>
                            {userCheckout === visit.student_id && (
                                <div className={styles.buttons}>
                                    {"Check Student Out?   "}
                                    <Button onClick={(e) => callHandleManualSubmit(e, userCheckout)} value="Yes" />
                                    <Button onClick={(e) => callSetUserCheckout(e, "")} value="No" />
                                </div>
                            )}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default VisitList;