import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getCurrentArpSession, toggleSubmittedArp, toggleAttendedArp, submitArp, getAllArpSessions, getArpSession } from '../../../../actions/arpSessionActions';

import Button from '../../../../components/button/button';
import Table from '../../../../components/table/table';

import style from '../tabs.module.css';
import styles from './arp.module.css';

const ARPSessionTab = ({ setInSession }) => {
    const dispatch = useDispatch();
    const arpSessionLoaded = useSelector(state => state.arpSessionLoaded);
    const arpSession = useSelector(state => state.arpSession);
    const arpPastSessions = useSelector(state => state.arpPastSessions);
    const arpPastSession = useSelector(state => state.arpPastSession);

    const [selectedPage, setSelectedPage] = useState("Attendance");
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    useEffect(() => {
        if(!arpSessionLoaded) {
            dispatch(getAllArpSessions());
            dispatch(getCurrentArpSession());
            setShowDisclaimer(false);
        }
    }, [arpSessionLoaded]);

    useEffect(() => {
        dispatch(getAllArpSessions());
        dispatch(getCurrentArpSession());
    }, []);

    const getColumns = () => {
        if(selectedPage === 'ARPs' || selectedPage === 'Previous ARP') {
            const columns = [
                {
                    title: 'Student',
                    accessor: 'student',
                    filter: true
                },
                {
                    title: 'Course',
                    accessor: 'course',
                    filter: true
                },
                {
                    title: 'Assignment',
                    accessor: 'homeworkTitle',
                    filter: true
                },
                {
                    title: 'Teacher',
                    accessor: 'teacher',
                    filter: true
                },
                {
                    title: 'Status',
                    accessor: 'submitted',
                    filter: true
                }
            ];
            if(selectedPage === 'ARPs') {
                columns.push({
                    title: '',
                    ignoreSort: true,
                    accessor: 'submit',
                    element: (e) => (
                        <Button
                            value="Toggle Status"
                            onClick={() => {
                                dispatch(toggleSubmittedArp(e))
                            }}
                        />
                    )
                });
            }
            return columns;
        }
        if (selectedPage === "Previous ARPs") {
            return [
                {
                    title: 'Submitted',
                    accessor: 'submitted'
                },
                {
                    title: '',
                    ignoreSort: true,
                    accessor: 'id',
                    element: (e) =>  (
                        <Button
                            value={'View'}
                            onClick={() => {
                                setSelectedPage('Previous Attendance');
                                dispatch(getArpSession({ id: e }));
                            }}
                        />
                    )
                },
            ]
        }
        const columns = [
            {
                title: 'Student First',
                accessor: 'first_name',
                filter: true
            },
            {
                title: 'Student Last',
                accessor: 'last_name',
                filter: true
            },
            {
                title: 'Attending',
                accessor: 'attending',
                filter: true
            },
        ];
        if(selectedPage === "Attendance") {
            columns.push({
                title: '',
                ignoreSort: true,
                accessor: 'student_id',
                element: (e) =>  <Button value={'Attendance'} onClick={() => dispatch(toggleAttendedArp({ student_id: e }))} />
            });
        }
        return columns;
    } 

    const getData = () => {
        if(selectedPage === 'ARPs' || selectedPage === 'Previous ARP') {
            const session = selectedPage === 'ARPs' ? arpSession : arpPastSession;
            const arps = session?.arps?.map(arp => (arp.students.map(arpStd => ({ ...arpStd, homeworkTitle: arp.homeworkTitle, course_id: arp.course_id })))).flat(2).map(arpStd => {
                const student = session?.students.find(student => student.student_id === arpStd.student_id);
                const course = session?.courses?.find(course => course.course_id === arpStd.course_id);
                const teacher = session?.staff.find(teacher => teacher.teacher_id === course.teacher_id);
                return {
                    ...arpStd,
                    student: `${student.last_name}, ${student.first_name}`,
                    teacher: `${teacher.last_name}, ${teacher.first_name}`,
                    course: `${course.course_name} - ${course.expression}`,
                    submitted: arpStd.submitted ? 'Submitted' : 'Missing',
                    submit: { id: arpStd.arp_id, student_id: arpStd.student_id }
                }
            });
            return arps || []
        }

        if (selectedPage === 'Previous ARPs') {
            return arpPastSessions.map(session => ({
                id: session.id,
                submitted: new Date(session.submitted).toDateString()
            }))
        }

        const session = selectedPage === 'Attendance' ? arpSession : arpPastSession;
        const arps = session?.students?.map(student => ({
            ...student,
            attending: session.attendance.find(attn => attn.student_id === student.student_id) ? 'Present' : 'Absent'
        }))
        return arps || [];
    }

    const toggleDefaultStyle = {
        width: '150px',
        borderRadius: '0',
        border: 'none',
        borderBottom: '1px solid gray',
        backgroundColor: 'inherit',
        fontSize: '15pt'
    };

    const toggleSelectedStyle = {
        ...toggleDefaultStyle,
        color: 'darkslategray',
        borderBottom: '1px solid darkslategray',
    }

    const getHeaderConfig = () => {
        let leftContent;
        let middleContent;
        let rightContent;

        if(selectedPage === 'Previous ARPs') {
            leftContent = (
                <Button
                    className={styles.backButton}
                    value={'< Back'}
                    onClick={() => {
                        setSelectedPage('Attendance');
                    }}  
                />
            );
            middleContent = <h2 className={style.header}>Previous ARP Sessions</h2>
        }
        if(selectedPage === 'Previous ARP' || selectedPage === 'Previous Attendance') {
            leftContent = (
                <Button
                    className={styles.backButton}
                    value={'< Back'}
                    onClick={() => {
                        setSelectedPage('Previous ARPs');
                    }}  
                />
            );
            middleContent = (
                <div className={styles.arpSessionsHeaderContain}>
                    <Button value="Attendance" onClick={() => setSelectedPage('Previous Attendance')} style={{...selectedPage === 'Previous Attendance' ? toggleSelectedStyle : toggleDefaultStyle, marginRight: '15px'}}/>
                    <Button value="ARPs" onClick={() => setSelectedPage('Previous ARP')} style={selectedPage === 'Previous ARP' ? toggleSelectedStyle : toggleDefaultStyle}/>
                </div>
            );
        }
        if(selectedPage === 'Attendance' || selectedPage === 'ARPs') {
            leftContent = <Button className={styles.backButton} onClick={() => setInSession(false)} value='< Back' />
            rightContent = (
                <>
                    <Button onClick={() => setSelectedPage("Previous ARPs")} value='Previous ARPs' style={{ marginRight: '10px'}} />
                    {arpSession?.arps?.length > 0 && <Button onClick={() => setShowDisclaimer(true)} value='Submit ARP' style={{ marginRight: '10px'}} /> }
                </>
            );
            middleContent = (
                <div className={styles.arpSessionsHeaderContain}>
                    <Button value="Attendance" onClick={() => setSelectedPage('Attendance')} style={{...selectedPage === 'Attendance' ? toggleSelectedStyle : toggleDefaultStyle, marginRight: '15px'}}/>
                    <Button value="ARPs" onClick={() => setSelectedPage('ARPs')} style={selectedPage === 'ARPs' ? toggleSelectedStyle : toggleDefaultStyle}/>
                </div>
            );
        }

        return {
            leftContent,
            middleContent,
            rightContent
        }
    }

    const sortOverride = selectedPage === "Attendance" ? 'first_name' : 'student';

    const showDisclaimerPage = (
        <div className={style.centerContain}>
            <h3 className={style.header} style={{ margin: '10px 0'}}>Are you sure you want to Submit All ARPs for Review?</h3>
            <h4 className={style.header} style={{ margin: '10px 0'}}>You will not be able to make any additional edits.</h4>
            <Button value="No" onClick={() => setShowDisclaimer(false)} style={{ marginRight: '10px'}}/>
            <Button value="Yes" onClick={() => dispatch(submitArp())} />
        </div>
    );

    const getContent = () => {
        if(showDisclaimer) {
            return showDisclaimerPage;
        }
        return <Table columns={getColumns()} data={getData()} headerConfig={getHeaderConfig()} sortOverride={sortOverride}/>
    }

    return (
        <div className={styles.container}>
            <div className={styles.arpSessionsBodyContain}>{getContent()}</div>
        </div>
    )
};

export default ARPSessionTab;