import {
    ARP_SESSION_RETRIEVED,
    ARP_SESSION_SUBMIT,
    SET_BANNER,
    ARP_PAST_SESSIONS_RETRIEVED,
    ARP_PAST_SESSION_RETRIEVED,
} from './actionTypes';
import { userAuthHeaders } from './authorizationHeaders';
import axios from 'axios';
export const getCurrentArpSession = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/arpsession/current`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: ARP_SESSION_RETRIEVED,
            payload: res.data.arpSession
        });
        }).catch(err => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
}

export const getAllArpSessions = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/arpsession/all`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: ARP_PAST_SESSIONS_RETRIEVED,
            payload: res.data.arpSessions
        });
        }).catch(err => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
}

export const getArpSession = (parameters) => async (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/arpsession/session`, parameters, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: ARP_PAST_SESSION_RETRIEVED,
            payload: res.data.arpSession
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
};

export const toggleAttendedArp = (parameters) => async (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/arpsession/attendance`, parameters, userAuthHeaders(getState)).then((res) => {
        // Put operations will not return any response, assuming we get a 200 we just update state with the parameters we provided
        // Shallow copy to rerender state
        const arpSession = { ...getState().arpSession };
        arpSession.attendance = res.data.attendance;
        dispatch({
            type: ARP_SESSION_RETRIEVED,
            payload: arpSession
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Successfully Toggled Attended Status.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
};

export const toggleSubmittedArp = (parameters) => async (dispatch, getState) => {
    axios.put(`${process.env.REACT_APP_SERVER_DOMAIN}/arp/student/submitted`, parameters, userAuthHeaders(getState)).then((res) => {
        // Put operations will not return any response, assuming we get a 200 we just update state with the parameters we provided
        // Shallow copy to rerender state
        const arpSession = { ...getState().arpSession };

        const arps = [...arpSession.arps].filter(arp => arp.id !== res.data.arp.id);
        arps.push(res.data.arp);
        arpSession.arps = arps;
        dispatch({
            type: ARP_SESSION_RETRIEVED,
            payload: arpSession
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Successfully Toggled Submitted Status.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}

export const submitArp = () => async (dispatch, getState) => {
    await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/arpsession/submit`, {}, userAuthHeaders(getState)).then((res) => {
        // Put operations will not return any response, assuming we get a 200 we just update state with the parameters we provided
        // Shallow copy to rerender state
        dispatch({
            type: ARP_SESSION_SUBMIT
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Updated ARP Session.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
};