import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./tabs.module.css";
import { getLocationVisits } from "../../../actions/visitActions";
import ListComponent from "../../../components/listComponent/listComponent";
import Modal from "../../../components/modal/modal";
import Button from '../../../components/button/button';
import AccountModal from "../../../components/modal/contents/accountModal/accountModal";
import { convertToCentral } from "../../../util";
import Table from "../../../components/table/table";
import { createAbsence, deleteAbsence } from "../../../actions/absenceActions";
import Input from "../../../components/input/input";

const AbsenceTab = () => {
    const dispatch = useDispatch();

    const students = useSelector(state => state.students);
    const absences = useSelector(state => state.absences);
    const user = useSelector(state => state.user);

    const [selectedPage, setSelectedPage] = useState('OPEN');
    const [studentIds, setStudentIds] = useState([]);
    const [reason, setReason] = useState('');
    
    useEffect(() => {
        setSelectedPage('OPEN');
        setStudentIds([]);
        setReason('');
    }, [absences])

    const updateStudentIds = (student_id) => {
        if(studentIds.includes(student_id)) {
            setStudentIds(studentIds.filter(student => student !== student_id));
        } else {
            setStudentIds([...studentIds, student_id]);
        }
    }

    const getColumns = () => {
        if (selectedPage === 'OPEN') {
            return [
                {
                    title: 'First Name',
                    accessor: 'first_name',
                    filter: true
                },
                {
                    title: 'Last Name',
                    accessor: 'last_name',
                    filter: true
                },
                {
                    title: 'Date',
                    accessor: 'date',
                    filter: true
                },
                {
                    title: 'Reason',
                    accessor: 'reason',
                    filter: true
                },
                {
                    title: '',
                    accessor: 'student_id',
                    element: (e) => user.roles.includes('ADMINISTRATOR') && <Button value="Delete" onClick={() => dispatch(deleteAbsence({ student_id: e }))} style={{ fontSize: '10pt', height: '40px', padding: '5px 15px'}} />
                }
            ]
        }
        return [
            {
                title: '',
                accessor: 'student_id',
                style: { justifyContent: 'flex-end' },
                element: (e) => (
                    <input
                        type='checkbox'
                        checked={studentIds.includes(e)}
                        onChange={() => updateStudentIds(e)}
                        style={{ height: '15px', width: '15px'}}
                    />
                )
            },
            {
                title: 'Student',
                accessor: 'studentName',
                style: { justifyContent: 'flex-start' },
                filter: true
            }
        ];
    }

    const getHeaderConfig = () => {
        if (selectedPage === 'OPEN') {
            return {
                middleContent: <h2 className={style.header}>Absent Students</h2>,
                rightContent: user.roles.includes('ADMINISTRATOR') && <Button value="Create Absence" onClick={() => setSelectedPage('CREATE')} style={{ marginRight: '10px' }}/>
            }
        }
        return {
            rightContent: studentIds.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Input placeholder="Reason" value={reason} onChange={(e) => setReason(e.target.value)} style={{ borderRadius: '0', marginRight: '10px'}} />
                    <Button value="Submit" disabled={reason === ''} onClick={() => dispatch(createAbsence({ student_ids: studentIds, reason }))} style={{ marginRight: '10px'}} />
                </div>
            ),
            middleContent: <h2 className={style.header}>Create Absence</h2>,
            leftContent: (
                <Button
                    className={style.backButton}
                    onClick={() => {
                        setStudentIds([]);
                        setSelectedPage('OPEN');
                        setReason('');
                    }}
                    value={'< Back'}
                />
            )
        }
    }

    const getData = () => {
        if (selectedPage === 'OPEN') {
            return absences.map(absent => {
                const student = students.find(student => student.student_id === absent.student_id);
                return {
                    first_name: student.first_name,
                    last_name: student.last_name,
                    date: absent.date,
                    reason: absent.reason,
                    student_id: absent.student_id
                }
            })
        }
        return students.filter(student => !absences.find(abs => abs.student_id === student.student_id)).map(student => ({ ...student, studentName: `${student.last_name}, ${student.first_name}`}));
    }

    return (
        <Table columns={getColumns()} data={getData()} headerConfig={getHeaderConfig()} />
    )
};

export default AbsenceTab;