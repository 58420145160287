import React, { useState } from "react";
import ARPTab from "./arpTab";
import ARPSessionTab from "./arpSessionTab";

const ARP = () => {
    const [inSession, setInSession] = useState(false);
    if(inSession) {
        return <ARPSessionTab setInSession={setInSession} />
    }

    return <ARPTab setInSession={setInSession} />
}

export default ARP;