import {
    SET_BANNER,
    ABSENCE_RETRIEVED,
} from './actionTypes';
import { userAuthHeaders } from './authorizationHeaders';
import axios from 'axios';
export const getAbsence = () => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/absences/all`, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: ABSENCE_RETRIEVED,
            payload: res.data.absences
        });
        }).catch((err) => {
            dispatch({
                type: SET_BANNER,
                payload: { msg: err.response.data.msg, color: 'red' }
            });
        });
}

export const createAbsence = (parameters) => async (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/absences`, parameters, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: ABSENCE_RETRIEVED,
            payload: res.data.absences
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Created Absence.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}

export const deleteAbsence = (parameters) => async (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/absences/delete`, parameters, userAuthHeaders(getState)).then((res) => {
        dispatch({
            type: ABSENCE_RETRIEVED,
            payload: res.data.absences
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Deleted Absence.', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    });
}