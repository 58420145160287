export const locationAuthHeaders = getState => {
    // Get token from localStorage
    const token = getState().locationAuthToken;

    // Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    };

    // If token, add to headers
    if (token) {
        config.headers["x-auth-token"] = token;
    }

    return config;
};

export const userAuthHeaders = getState => {
    // Get token from localStorage
    const token = getState().userAuthToken;

    // Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    };

    // If token, add to headers
    if (token) {
        config.headers["x-auth-token"] = token;
    }

    return config;
};