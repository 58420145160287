import {
    POST_VISIT_FAILURE,
    VISITS_RETRIEVED,
    SET_SOCKET_VISITS,
    SET_BANNER
} from './actionTypes';
import { userAuthHeaders } from './authorizationHeaders';
import axios from 'axios';


export const getVisits = (date_range) => async (dispatch, getState) => {
    await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/visits/`, {params: {start: date_range[0], end: date_range[1]}, ...userAuthHeaders(getState) }).then((res) => {
        dispatch({
            type: VISITS_RETRIEVED,
            payload: res.data.visits
        });
        dispatch({
            type: SET_BANNER,
            payload: { msg: 'Updated Student Visits', color: 'green' }
        });
    }).catch((err) => {
        dispatch({
            type: SET_BANNER,
            payload: { msg: err.response.data.msg, color: 'red' }
        });
    })
}

export const postVisitFailure = (err) => (dispatch, getState) => {
    dispatch({
        type: POST_VISIT_FAILURE,
        payload: err
    });
};

export const setLocationVisitsFromSocket = (res) => async (dispatch) => {
    dispatch({
        type: SET_SOCKET_VISITS,
        payload: res
    })
};