import React from "react";
import style from './select.module.css';

const Select = (props) => {
    return (
        <div className={style.container} style={props.containerStyle}>
            {props.header && <div className={style.header}>{props.header}</div>}
            <select className={style.input} value={props.value} onChange={props.onChange}>
                {props.options.map(option => <option key={props.options.indexOf(option)} value={option.value}>{option.label}</option>)}
            </select>
        </div>
    )
}

export default Select;