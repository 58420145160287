import React, { useEffect, useState } from "react";
import styles from './pageHeader.module.css';
import { useDispatch, useSelector } from "react-redux";
import { SET_BANNER } from "../../actions/actionTypes";

const PageHeader = ({ children }) => {
    const dispatch = useDispatch();
    const banner = useSelector(state => state.banner);

    const [bannerMessage, setBannerMessage] = useState(null);
    const [bannerColor, setBannerColor] = useState(null);
    const [fadeClass, setFadeClass] = useState('');

    useEffect(() => {
        if(banner) {
            setFadeClass(styles.fadeIn);
            setBannerMessage(banner.msg);
            setBannerColor(banner.color);

            setTimeout(() => {
                dispatch({
                    type: SET_BANNER,
                    payload: null
                })
            }, 2000);
        } else {
            setFadeClass(styles.fadeOut);
            // Set timeout to delay hiding the element until after the animation
            setTimeout(() => {
                setBannerMessage(null);
                setBannerColor(null);
            }, 500); // Match the duration of the fade-out animation
        }
    }, [banner]);
    return (
        <div className={styles.pageContainer}>
            <div className={styles.headerContainer}>
                <h2>Pius X Apps</h2>
            </div>
            <div className={styles.childrenContainer}>{children}</div>
            {bannerMessage && <div className={`${styles.banner} ${fadeClass}`} style={{ backgroundColor: bannerColor }}>{bannerMessage}</div>}
        </div>
    )
}

export default PageHeader;