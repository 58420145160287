import React, { useEffect, useState } from "react";
import style from './locationScanner.module.css';
import ListenButton from "../../components/listenButton/listenButton";
import VisitList from "../../components/visitList/visitList";
import BackButton from "../../components/backButton/backButton";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions/actions";
import io from 'socket.io-client';
import { postVisitFailure, setLocationVisitsFromSocket } from "../../actions/visitActions";
import ManualCheckin from "../../components/manualCheckin/manualCheckin";

const LocationScanner = () => {
    const [socket, setSocket] = useState(null)
    const location = useSelector(state => state.location);
    const locationAuthToken = useSelector(state => state.locationAuthToken);

    const [manualDisplay, setManualDisplay] = useState(false);
    const [userCheckout, setUserCheckout] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if(location) {
            const sock = io(process.env.REACT_APP_SERVER_SOCKET, {
                path: '/socket/',
                auth: {
                    token: locationAuthToken
                }
            });
            sock.on('connect', () => {
                console.log('connected to server');
                sock.on(`${location.id}:visit`, (vis) => {
                    dispatch(setLocationVisitsFromSocket(vis))
                });
    
                sock.on('visitFailure', (err) => {
                    dispatch(postVisitFailure(err));
                })
    
                sock.emit('visit:get');
        
                sock.on('connect_error', () => {
                    console.log('failed to connect to server');
                });
            });
            setSocket(sock);
            // Cleanup function to close the socket connection
            return () => {
                if (sock) {
                    sock.disconnect(); // Close the connection
                    console.log('Socket connection closed');
                }
            };
        }
    }, [location]);

    const handleManualSubmit = (student_id) => {
        setManualDisplay(false);
        setUserCheckout("");
        socket.emit('visit:post', student_id);
    }
    
    return (
        <div className={style.container}>
            <ListenButton socket={socket} />
            <BackButton display={locationAuthToken !== null} onClick={() => dispatch(logout())} customText={"< Logout"} />
            <div className={style.thirdContainer}>
                <ManualCheckin callback={(student_id) => handleManualSubmit(student_id)}/>
            </div>
            <div className={style.twoThirdContainer}>
                <div className={style.headerContain}>
                    <h2 className={style.heading}>{location?.location_name} Attendance</h2>
                </div>
                <VisitList setUserCheckout={setUserCheckout} userCheckout={userCheckout} handleManualSubmit={handleManualSubmit} />
            </div>
        </div>
    );
}

export default LocationScanner;