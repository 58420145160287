import React, { useEffect } from 'react'
import Login from './pages/login/login';
import LocationScanner from './pages/locationScanner/locationScanner';
import NotFound from './components/404/404';
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import PageHeader from './components/pageHeader/pageHeader';
import UserLanding from './pages/userLanding/userLanding';
import { getLocation, getUser, logout } from './actions/actions';

const LocationAuthRoute = ({ locationAuthToken }) => {
  if (locationAuthToken && locationAuthToken != null) {
    return <Outlet />;
  }
  return <Navigate to={"/login"} replace />;
};

const UserAuthRoute = ({ userAuthToken }) => {
  if (userAuthToken && userAuthToken != null) {
    return <Outlet />;
  }
  return <Navigate to={"/login"} replace />;
};

const UnauthRoute = ({ locationAuthToken, userAuthToken }) => {
  if (userAuthToken && userAuthToken != null) {
    return <Navigate to={"/user"} replace />;
  }
  if (locationAuthToken && locationAuthToken != null) {
    return <Navigate to={"/location"} replace />;
  }
  return <Outlet />;
};

const App = () => {
  const user = useSelector(state => state.user);
  const location = useSelector(state => state.location);
  const locationAuthToken = useSelector(state => state.locationAuthToken);
  const userAuthToken = useSelector(state => state.userAuthToken);
  const locationAuthExpiresBy = useSelector(state => state.locationAuthExpiresBy);
  const userAuthExpiresBy = useSelector(state => state.userAuthExpiresBy);

  // KNOWN BUG, spamming login logout will not reset the setTimeouts
  const dispatch = useDispatch();
  useEffect(() => {
    if(locationAuthExpiresBy != null) {
      setTimeout(() => {
        dispatch(logout());
      }, new Date(locationAuthExpiresBy).getTime() - new Date().getTime());
    }

    if(userAuthExpiresBy != null) {
      setTimeout(() => {
        dispatch(logout());
      }, new Date(userAuthExpiresBy).getTime() - new Date().getTime());
    }
  }, [locationAuthExpiresBy, userAuthExpiresBy]);

  useEffect(() => {
    if(!user && userAuthToken) {
      dispatch(getUser());
    }

    if(!location && locationAuthToken) {
      dispatch(getLocation());
    }
  }, []);
  
  return (
    <PageHeader>
      <BrowserRouter>
        <Routes>
          <Route element={<UnauthRoute locationAuthToken={locationAuthToken} userAuthToken={userAuthToken}/>}>
            <Route
              path="/login"
              element={<Login />}
            />
            <Route
              path="/"
              element={<Navigate to={"/login"} replace />}
            />
          </Route>
          <Route element={<UserAuthRoute userAuthToken={userAuthToken} />}>
            <Route
              path="/user"
              element={<UserLanding />}
            />
          </Route>
          <Route element={<LocationAuthRoute locationAuthToken={locationAuthToken} />}>
            <Route
              path="/location"
              element={<LocationScanner />}
            />
          </Route>
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </BrowserRouter>
    </PageHeader>
  );
}

export default App;
